var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "body"
  }, [_c('h3', [_c('strong', [_c('span', [_vm._v(_vm._s(_vm.statisticTitle) + " ")])])]), _c('hr')]), _c('b-avatar', {
    staticClass: "mb-1",
    attrs: {
      "variant": "light-".concat(_vm.color),
      "size": "60"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "25",
      "icon": _vm.icon
    }
  })], 1), _c('div', {
    staticClass: "truncate"
  }, [_vm.loading ? _c('h2', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _c('h2', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm.statistic.in_out === 'permission' ? _c('div', [_c('small', [_c('i', [_vm._v("authentication required")])])]) : _c('div', [_vm._v(" " + _vm._s(_vm.statistic.in_out) + " | " + _vm._s(_vm.statistic.in_only) + " ")])]), _c('small', [_c('span', [_vm._v("IN Area "), _c('strong', [_vm._v("|")]), _vm._v(" IN Total")])]), !this.disableViewLimit ? _c('span', [_c('hr'), _c('b-progress', {
    class: _vm.progressBarClass,
    attrs: {
      "max": _vm.maxApprovement
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": _vm.currentApprovement
    }
  }, [_c('strong', {
    staticClass: "text-dark"
  }, [_vm._v(" " + _vm._s(_vm.currentApprovement) + " / " + _vm._s(_vm.maxApprovement) + " ")])])], 1), _c('small', [_vm._v("Organized Queue")])], 1) : _c('span')])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }