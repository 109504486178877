<template>
  <b-card class="text-center">
    <div class="body">
      <h3>
        <strong>
          <span>{{ statisticTitle }} </span>
        </strong>
      </h3>
      <hr />
    </div>
    <b-avatar class="mb-1" :variant="`light-${color}`" size="60">
      <feather-icon size="25" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h2 v-if="loading" class="mb-25 font-weight-bolder">
        <b-spinner small />
      </h2>
      <h2 v-else class="mb-25 font-weight-bolder">
        <div v-if="statistic.in_out === 'permission'">
          <small>
            <i>authentication required</i>
          </small>
        </div>
        <div v-else>
          {{ statistic.in_out }} | {{ statistic.in_only }}
        </div>
      </h2>
      <small>
        <span>IN Area <strong>|</strong> IN Total</span>
      </small>
      <span v-if="!this.disableViewLimit">
        <hr />
        <!-- <BBadge> -->
        <b-progress :max="maxApprovement" :class="progressBarClass">
          <b-progress-bar :value="currentApprovement">
            <strong class="text-dark">
              {{ currentApprovement }} / {{ maxApprovement }}
            </strong>
          </b-progress-bar>
        </b-progress>
        <!-- </BBadge> -->
        <small>Organized Queue</small>
      </span>
      <span v-else>
        <!-- <hr/>
        🔑
        <br/>
        <small>SAL Only</small> -->
      </span>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    statistic: {
      type: [Object, Array],
      default: 0
    },
    statisticTitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    disableViewLimit: {
      type: null,
      default: true
    },
    maxApprovement: {
      type: null,
      default: null
    },
    currentApprovement: {
      type: null,
      default: null
    }
  },
  computed: {
    progressBarClass() {
      const progressPercentage =
        (this.currentApprovement / this.maxApprovement) * 100

      if (progressPercentage < 30) {
        return 'progress-bar-warning'
      } else if (progressPercentage >= 30 && progressPercentage < 70) {
        return 'progress-bar-info'
      } else {
        return 'progress-bar-success'
      }
    }
  },
  data() {
    return {
      // disableViewLimit: true,
      // maxApprovement: 0,
      // currentApprovement: 0
    }
  },
  created() {
    //
  },
  methods: {
    //
  }
}
</script>
